<template>
    <v-card>
        <v-card-title class="text-h6 mb-5">Hàng vào sọt</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field v-model="code" class="c-input-small" :label="$t('labels.sku')" dense outlined clearable
                        autofocus hide-details ref="inputIndex_1" append-icon="mdi-qrcode-scan"
                        @click:append="showQRCodeScan('code')" @keyup.enter="checkItem"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="basket_code" class="c-input-small" :label="$t('labels.basket_code')" dense
                        outlined clearable hide-details ref="inputIndex_2" @keyup.enter="checkBasketItem"
                        append-icon="mdi-qrcode-scan" @click:append="showQRCodeScan('basket_code')"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model.number="quantity" type="number" class="c-input-small"
                        :label="$t('labels.quantity')" dense outlined clearable hide-details
                        ref="inputIndex_3" @keyup.enter="confirm"></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancel">
                {{ $t('labels.cancel') }}
            </v-btn>
            <v-btn color="green darken-1" text @click="confirm" :disabled="isDisabled">
                {{ $t('labels.confirm') }}
            </v-btn>
        </v-card-actions>

        <v-dialog v-model="qrScanDialog" max-width="100vw">
            <QRCodeScanner v-if="qrScanDialog" :name="qrScanType" :auto-close="false" @close="hideQRCodeScan"
                @onScanned="onQRScanned" />
        </v-dialog>
    </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
    name: "FindGoodsNotFound",
    data: () => ({
        isLoading: false,
        code: null,
        basket_code: null,
        quantity: null,
        remaining_quantity: null,
        qrScanDialog: false,
        qrScanType: null,
    }),
    computed: {
        isDisabled() {
            return !this.code || !this.basket_code || this.quantity == null || this.quantity < 0 || this.quantity > this.remaining_quantity
        }
    },
    methods: {
        showQRCodeScan(type) {
            this.qrScanDialog = true
            this.qrScanType = type
        },
        hideQRCodeScan() {
            this.qrScanDialog = false
            this.qrScanType = null
        },
        onQRScanned(filter) {
            this[filter.name] = filter.value
            switch (filter.name) {
                case 'code':
                    this.checkItem()
                    break
                case 'basket_code':
                    this.checkBasketItem()
                    break
                case 'quantity':
                    this.confirm()
                    break
            }
        },
        nextInputFocus(index) {
            if (index > 3) {
                return false
            }
            this.$nextTick(() => {
                if (this.$refs[`inputIndex_${index + 1}`]) {
                    this.$refs[`inputIndex_${index + 1}`].focus()
                } else {
                    this.nextInputFocus(index + 1)
                }
            })
        },
        async checkItem() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true
            try {
                await httpClient.post('/find-goods/v1/employee-check-item', { code: this.code })
                this.nextInputFocus(1)
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                this.code = null
                this.isLoading = false
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.$root.$emit('playErrorAudio')
            }
        },
        async checkBasketItem() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true
            try {
                const { data } = await httpClient.post('/find-goods/v1/employee-check-item-in-basket', { code: this.code, basket_code: this.basket_code })
                this.remaining_quantity = data.remaining_quantity
                this.nextInputFocus(2)
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                this.basket_code = null
                this.isLoading = false
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.$root.$emit('playErrorAudio')
            }
        },
        cancel() {
            this.$emit('cancel', true)
        },
        refreshData() {
            this.$emit('refreshData')
        },
        async confirm() {
            if (this.isDisabled) {
                return false
            }
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true
            try {
                await httpClient.post('/find-goods/v1/employee-item-finded', {
                    code: this.code,
                    basket_code: this.basket_code,
                    quantity: this.quantity
                })
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
                this.refreshData()
                this.cancel()
            } catch (e) {
                this.isLoading = false
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.$root.$emit('playErrorAudio')
            }
        }
    }
}
</script>